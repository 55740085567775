import Vue from 'vue'
import VueRouter from 'vue-router'
import router_modular from '@/router/router_modular/index.js'
import store from "@/store"
Vue.use(VueRouter)

const routes = [
  ...router_modular
]

const router = new VueRouter({
  routes,
  mode:"history",
  scrollBehavior(to, from, savePosition) {
    // return 期望滚动到哪个的位置
    return {
      y: 0
    }
  }
})
//全局路由守卫
router.beforeEach((to,from,next)=>{
  if (to.meta.title) {
    document.title = to.meta.title
  }
  if(store.state.routes.length==0){
    store.dispatch("getRoutes")
      .then(res=>{
        next()
      })
  }else{
    
    next()
  }
})
router.afterEach((to,from)=>{


})
export default router
