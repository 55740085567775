import Vue from 'vue'
import Vuex from 'vuex'
import store_modular from '@/store/store_modular/index.js'
import router from "@/router/router_modular"
Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    routes:[]
  },
  getters: {
  },
  mutations: {
    GETROUTES(state,routes){
      state.routes = routes
    }
  },
  actions: {
    getRoutes({commit}){
      new Promise((resolve,reject)=>{
        commit("GETROUTES",[...router])
        resolve([...router])
      })
    }
  },
  modules: {
    store_modular
  }
})
