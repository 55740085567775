import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
/* 引入移动端适配 */
import 'amfe-flexible';
import Vant from 'vant';
import 'vant/lib/index.css';
import axios from 'axios'
import wx from "weixin-js-sdk"
import layout from "./components/layout.vue"
import utils from "./utils/utils"

// 放置全局方法
Vue.prototype.$Utils = utils

// 注册全局组件
Vue.component(layout.name,layout)

Vue.prototype.$wx = wx

Vue.use(Vant);
Vue.config.productionTip = false

new Vue({
  axios,
  router,
  store,
  render: h => h(App)
}).$mount('#app')
