import router from "@/router/index"
// 格式化去时间
function formatDatetime(time) {
  if (time instanceof Date) {
    let year = time.getFullYear();
    let month = time.getMonth() + 1;
    let day = time.getDate();
    let hours = time.getHours();
    let minutes = time.getMinutes();
    if (month < 10) month = "0" + month;
    if (day < 10) day = "0" + day;
    if (hours < 10) hours = "0" + hours;
    if (minutes < 10) minutes = "0" + minutes;
    return year + "-" + month + "-" + day + " " + hours + ":" + minutes;
  } else {
    return time;
  }
}

// 输入框去特殊字符
function validateValue(obj, prop) {
  var IllegalString =
    "[`~!#$^&*()=|{}':;',\\[\\].<>/?~！#￥……&*（）——|{}【】‘；：”“'。，、？123456789]“”";
  var index = obj[prop].length - 1;
  var s = obj[prop].charAt(index);
  // console.log(s)
  if (IllegalString.indexOf(s) >= 0) {
    s = obj[prop].substring(0, index);
    obj[prop] = s;
  }
}

// 身份证校验,传目标对象和需要校验的那个属性过来可以去空格之后再校验
function validateIdCard(obj = "", prop = "") {
  return function (value) {
    let reg =
      /^[1-9]\d{5}(19|20)\d{2}((0[1-9])|(1[0-2]))(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$/;
    if (obj && prop) {
      obj[prop] = value = value.replace(/\s/g, "");
    }

    if (value.length != 18 && value.length != 15) return false;

    if (value.length == 18) {
      if (!reg.test(value)) {
        return false;
      }
    }
    // 当前15位身份证没有正则只做了位数的校验
  };
}

//手机号校验
function verifyPhone(phoneNumber) {
  var phoneNumberPattern = /^1[3-9]\d{9}$/;
  return phoneNumberPattern.test(phoneNumber);
}

function getOpenid(url) {
  let openId = "";
  //进入列表 调接口查询是否绑定openid || 本地存储是否存在openid 存在就留在当前页面 否则就跳转登录页进行绑定
  //   doctorLoginApi.isOpenId().then(res=>{
  //    if(res.data.openId){
  //     openId = res.data.openId
  //   }
  // })
  if (!localStorage.getItem("wxOpenid")) {
   if(url!='doctorReport/login'){
    router.push({ path: "/doctorReport/login" });
   }
  } else {
    if (url!='/doctorReport/getReportList') {
     router.push({ path: "/doctorReport/getReportList" });
    }
  }
}

export default {
  formatDatetime,
  validateValue,
  validateIdCard,
  verifyPhone,
  getOpenid
};
