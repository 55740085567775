<template>
  <div id="app">
    <keep-alive :include="aliveRoute">
      <router-view/>
    </keep-alive>
  </div>
</template>
<script>
  export default{
    data(){ 
      return{
      }
      
    },  
    computed:{
      aliveRoute(){
        let routes = this.$store.state.routes.filter(item=>item.meta&&item.meta.keepAlive)

        routes = routes.map(item=>item.name)

        return routes
         
      }
    },
    mounted(){

    },
  }
</script>
<style lang="less">
body, h1, h2, h3, h4, h5, h6, hr, p, blockquote, /* structural elements 结构元素 */
dl, dt, dd, ul, ol, li, /* list elements 列表元素 */
pre, /* text formatting elements 文本格式元素 */
fieldset, lengend, button, input, textarea, /* form elements 表单元素 */
th, td {
  /* table elements 表格元素 */
  margin: 0;
  padding: 0;
}
img{
  vertical-align: bottom;
}
// body{
//   font-size: 15px !important;
// }
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}
</style>
