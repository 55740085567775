<template>
	<div class="container">
		<img class="logo" src="@/assets/images/web_logo.png" alt="">
		<van-row class="bar-img">
			<img :src="imageUrl" alt="">
		</van-row>
		<van-row class="content">
            <div class="title">
                {{title?title:routeTitle}}
            </div>
			<slot></slot>
		</van-row>
        <div class="footer">
            <p class="title" v-if="$route.path=='/logistics'">
                <a href="https://r.kanglailab.com/index" target="_blank">康来生物管理平台</a>
            </p>
            <p v-else class="title">版权所有 © 长沙康来生物科技有限公司</p>
            <p><a href="https://beian.miit.gov.cn/" target="_blank" rel="nofollow noopener">湘ICP备20001570号-1</a></p>
        </div>
	</div>
</template>

<script>
export default {
    name:"Layout",
    props:{
        title:{
            default:"",
            type:String,
        },
        imgUrl:{
            default:"",
            type:String
        }
    },
	data() {
		return {
            imageUrl:"",
		}
	},
    computed: {
        routeTitle(){
            if(this.$route.meta&&this.$route.meta.title){
                let title = this.$route.meta.title
                title = title.replace("康来生物-","")
                return  title
            }
        }
	},
    mounted(){
        if(!this.imgUrl){
            this.imageUrl = require("@/assets/images/disanfang1.jpg")
        }else{
            console.log(this.imgUrl)
            this.imageUrl = this.imgUrl
        }
    },  
	methods: {
		
	}
}
</script>

<style lang="less" scoped>
    	.container{
		// background-color: #F7F8FA;
        background-color: #F8F8F8;
        min-height: 100vh;
        padding-bottom: 60px;
        box-sizing: border-box;
        position: relative;
		.logo{
			width: 100px;
            padding: 10px 0 0 10px;
		}
		.bar-img{
			margin-top: 10px;
			img{
				width: 100%;
			}
		
        }
        .content{
            &>.title{
                color: #2bad37;
                text-align: center;
                font-size: 18px;
                font-weight: bold;
                padding: 10px 0;
            }
        }
		.content::after,.content::before{
			display: table;
			content: "";
		}
        .footer{
            height: 40px;
            width: 100%;
            position: absolute;
            bottom: 0;
            box-sizing: border-box;
            text-align: center;
            font-size: 10px !important;
            margin-top: 10px;
            padding-bottom: 4px;
            margin-bottom: 10px;
            .title {

                margin-bottom: 4px;
            }
           
        }
	}
</style>
